import { GroupChannel, Member } from '@sendbird/chat/groupChannel';

export interface IChannel extends GroupChannel {
  channel_url: string;
  cover_url: string;
  member_count: number;
}

export interface IMember extends Member {
  user_id: string;
}

export interface IMetaDataChannelItem {
  chatType: string;
  courseName: string;
  courseSubject: string;
  partnerFullName: string;
  partnerAvatar: string;
}

export interface IMetaDataChannel {
  participant: IMetaDataChannelItem
  mentor: IMetaDataChannelItem
  tutor: IMetaDataChannelItem;
  class: IMetaDataChannelItem;
}

export enum ChatType {
  Tutor = 'tutor',
  Participant = 'participant',
  Mentor = 'mentor',
  Classroom = 'class',
}
