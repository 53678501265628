import { AxiosResponse } from 'axios';
import $api from '../http';
import API_ROUTES from '../constants/apiRoutes';
import { IUserGetAvatar, IUserGetChatInfo, IUserGetProfile } from './interfaces/IUserService';

export default class UserServices {
  static async getUserInfo(): Promise<IUserGetProfile> {
    return $api.get(
      `${process.env.NEXT_PUBLIC_API_URL || ''}${API_ROUTES.USER_INFO}`,
    );
  }

  static async getAvatar(): Promise<IUserGetAvatar> {
    return $api.get(
      `${process.env.NEXT_PUBLIC_API_URL || ''}${API_ROUTES.ATTACHMENTS}${API_ROUTES.AVATAR}`,
      { responseType: 'blob' },
    );
  }

  static async setAvatar(data: FormData, isUpdate = false): Promise<AxiosResponse> {
    return $api[isUpdate ? 'patch' : 'post'](
      `${process.env.NEXT_PUBLIC_API_URL || ''}${API_ROUTES.ATTACHMENTS}${API_ROUTES.AVATAR}`,
      data,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    );
  }

  static async getChatUserInfo(): Promise<IUserGetChatInfo> {
    return $api.get(
      `${process.env.NEXT_PUBLIC_API_URL || ''}${API_ROUTES.CHAT}${API_ROUTES.USERS}`,
    );
  }
}
