import $api from '../http';
import { IStudentsListData } from './interfaces/IStudentsService';
import { UserRole } from '../models/user.model';
import API_ROUTES from '../constants/apiRoutes';
import { addEndingForRole } from '../utils/helper';

export default class StudentsListServices {
  static async getStudentsList(
    classOrCollegeId: string,
    role: UserRole,
    skip: number,
    take: number,
    session: string,
  ): Promise<IStudentsListData> {
    if (role === UserRole.Mentor) {
      return $api.get(
        `${process.env.NEXT_PUBLIC_API_URL
        || ''}${addEndingForRole(role)}/${API_ROUTES.PARTICIPANTS}`
          .concat(`?collegeId=${classOrCollegeId}&session=${session}&skip=${skip}&take=${take}`),
      );
    }
    return $api.get(
      `${process.env.NEXT_PUBLIC_API_URL
          || ''}${addEndingForRole(role)}/${classOrCollegeId}/${API_ROUTES.PARTICIPANTS}?skip=${skip}&take=${take}`,
    );
  }
}
