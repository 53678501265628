import {
  createSlice,
} from '@reduxjs/toolkit';
import INotification, { TypesOfNitifcation } from './interfaces/INotification';

export const initialState: INotification = {
  notificationList: [],
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    resetState: () => initialState,
    dismissNotification: (state: INotification, action: { payload: number }) => {
      state.notificationList = state.notificationList.filter(({ id }) => id !== action.payload);
    },
    addNotification: (
      state: INotification,
      action: {
        payload: {
          message: string,
          id: number,
          type?: TypesOfNitifcation.SUCCESS | TypesOfNitifcation.ERROR
        }
      },
    ) => {
      state.notificationList = [...state.notificationList, action.payload];
    },
  },
});

export default notificationsSlice.reducer;

export const { resetState, addNotification, dismissNotification } = notificationsSlice.actions;
