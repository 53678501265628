import { GroupChannel } from '@sendbird/chat/groupChannel';
import { IStudentListItem, IStudentListItemChannel } from '../models/students.model';
import {
  ChatType,
  IChannel,
  IMember,
  IMetaDataChannel,
} from '../models/messages.model';

const transformStudentsData = (
  students: Array<IStudentListItem>,
  channels: Array<GroupChannel>,
  role: ChatType,
): Array<IStudentListItemChannel> => (
  students.map((item) => {
    const studentChannel = channels.filter((channel) => {
      const parsedData = JSON.parse(channel.data) as IMetaDataChannel;
      const dataItemRole = parsedData[role];

      return dataItemRole.chatType === ChatType.Classroom;
    }).filter((channel) => {
      const channelMembers = channel.members as IMember[];
      const studentChannelMember = channelMembers.filter((member) => member.user_id === item.userId);

      if (studentChannelMember.length > 0) {
        return studentChannelMember;
      }

      return false;
    }) as Array<IChannel>;

    return {
      ...item,
      channel: studentChannel[0],
    };
  })
);

export default transformStudentsData;
