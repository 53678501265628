import { IIncidentUser } from './user.model';

export interface ICourseRole {
  classId: string;
  courseId: string;
  courseSubject: string;
  courseName: string;
  collegeId: string;
  ageRange: string;
  locationName: string;
  collegeName: string;
  classroomName: string;
  collegeAddress: string;
  startAt: string;
  endAt: string;
  progressStatus: ProgressStatus;
  type: string;
  session: string;
}

export interface ICourseMentorInformation {
  id: string;
  arriveDescription: string;
}

export interface ICollegeManager {
  avatar: string;
  fullName: string;
  email: string;
  phoneNumber: string;
}

export enum TaskStatus {
  NotStarted = 'not_started',
  Completed = 'completed',
  Submitted = 'submitted',
  InProgress = 'in_progress',
  NotAvailableYet = 'not_available_yet',
}

export interface ITask {
  id: string;
  title: string;
  description: string;
  status: TaskStatus;
  dueDate: string;
}

export interface IImportantUpdate {
  id: string;
  updatedByMentor: string;
  updatedByTutor: string;
  class: unknown;
  updatedSource: string;
  updatedFor: string;
  createdAt: string;
  title: string;
  description: string;
}

export interface IEvent {
  eventId: string;
  title: string;
  description: string;
  startAt: string;
  endAt: string;
  locationName: string;
  locationId: string;
  isBooked: boolean;
}

export interface IIncident {
  incidentId: string;
  location: string;
  firstInvolvedPerson: string;
  secondInvolvedPerson: string;
  description: string;
  comment: string;
  status: string;
  title: string;
  incidentDate: string;
  classId: string;
  mentor: IIncidentUser;
  tutor: IIncidentUser
}

export enum ProgressStatus {
  Enrolled = 'enrolled',
  PreArrivalInformation = 'pre_arrival_information',
  DuringCourse = 'during_the_course',
  Alumni = 'alumni',
}

export interface ICollege {
  college: {
    createdAt: Date,
    updatedAt: Date,
    id: string,
    name: string,
    collegeMapUrl: string,
    location: {
      createdAt: Date,
      updatedAt: Date,
      id: string,
      name: string,
      address: string,
      type: string
    }
  }
}
