import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import StudentsListServices from '../../services/studentsListServices';
import IStudents from './interfaces/IStudents';
import { UserRole } from '../../models/user.model';
import { ChatType } from '../../models/messages.model';
import { FIRST_PAGE, STUDENTS_PER_PAGE } from '../../constants/helperConstants';
import MessagesService from '../../services/messagesService';
import transformStudentsData from '../../utils/students';
import { IEvaluationsUser } from '../../pages/Evaluations/interfaces/IEvaluations';

export const initialState: IStudents = {
  isLoading: true,
  items: [],
  currentPage: FIRST_PAGE,
  totalPages: 0,
  itemsWithoutChannels: [],
  fetchTrigger: false,
};

export const getStudentsList = createAsyncThunk(
  'studentsSlice/getStudentsList',
  async (
    {
      classOrCollegeId, role, session, mentorId,
    }: { classOrCollegeId: string, role: UserRole, session: string, mentorId: string },
    { rejectWithValue, getState },
  ) => {
    const { students: { currentPage } } = getState() as { students: IStudents };

    try {
      const [students, channels] = await Promise.all([await StudentsListServices
        .getStudentsList(classOrCollegeId, role, (currentPage - 1) * STUDENTS_PER_PAGE, STUDENTS_PER_PAGE, session),
      MessagesService.getChannelList(classOrCollegeId, role, mentorId, session),
      ]);

      return {
        items: transformStudentsData(
          students.data.data.items,
          channels.data.data.channels,
          role.toLowerCase() as ChatType,
        ),
        count: students.data.data.count,
        itemsWithoutChannels: students.data.data.items,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const studentsSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {
    resetState: () => initialState,
    setCurrentPage: (state, { payload }: { payload: number }) => {
      state.currentPage = payload;
    },
    setItemsWithoutChannels: (state, { payload }: { payload: Array<IEvaluationsUser> }) => {
      state.itemsWithoutChannels = payload;
    },
    setFetchTrigger: (state, { payload }: { payload: boolean }) => {
      state.fetchTrigger = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStudentsList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStudentsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.items = action.payload.items;
        state.itemsWithoutChannels = action.payload.itemsWithoutChannels;
        state.totalPages = Math.ceil(action.payload.count / STUDENTS_PER_PAGE);
      })
      .addCase(getStudentsList.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default studentsSlice.reducer;

export const {
  resetState, setCurrentPage, setItemsWithoutChannels, setFetchTrigger,
} = studentsSlice.actions;
