export const ROUTES = {
  LOGIN: '/login',
  TIME_TABLE: '/time-table',
  STUDENTS: '/students/',
  EVALUATIONS: '/evaluations/',
  YOUR_FEEDBACK: '/your-feedback/',
  ATTENDANCE: '/attendance/',
  FORGOT_PASSWORD: '/account/reset',
  RESET_PASSWORD: '/password/reset',
  INCIDENT_REPORT: '/incident-report/',
  SUCCESS: '/success',
  ATTENDANCE_REGISTER: '/attendance/register/',
};

export const ROUTES_WITHOUT_SIDEBAR_AND_HEADER = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/account/reset',
  RESET_PASSWORD: '/password/reset',
  SUCCESS: '/success',
};

export const emptyRoutes = '/';
