import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import IUser from './interfaces/IUser';
import UserServices from '../../services/userService';

export const initialState: IUser = {
  email: '',
  firstName: '',
  isLoading: true,
  id: '',
  isVerified: false,
  lastName: '',
  roles: [],
  userChatData: null,
};

export const getUserInfo = createAsyncThunk(
  'user/getUserInfo',
  async (_, { rejectWithValue }) => {
    try {
      const { data: { data } } = await UserServices.getUserInfo();

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getChatUserInfo = createAsyncThunk(
  'user/getChatUserInfo',
  async (_, { rejectWithValue }) => {
    try {
      const { data: { data } } = await UserServices.getChatUserInfo();

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfo.fulfilled, (state, action) => ({ ...state, ...action.payload }))
      .addCase(getChatUserInfo.fulfilled, (state, action) => ({ ...state, userChatData: action.payload }));
  },
});

export default userSlice.reducer;

export const { resetState } = userSlice.actions;
