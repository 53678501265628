import { UserRole } from '../models/user.model';
import { ROUTES_WITHOUT_SIDEBAR_AND_HEADER } from '../constants/routes';

export const setToLocalStorage = (key: string, data: string) => {
  window.localStorage.setItem(key, data);
};

export const getFromLocalStorage = (key : string) => window.localStorage.getItem(key);

export const removeLocalStorage = (key: string) => {
  window.localStorage.removeItem(key);
};

export const clearStorage = () => {
  window.localStorage.clear();
};

export const findIndexItem = (
  array: { [key: string]: string | number }[],
  name: string,
  id: string | number,
) => array.findIndex(
  (el) => el[name] === id,
);

const searchRequest = (array: string[], search: string) => {
  let items = [];
  items = array?.filter((item) => item.toLowerCase().indexOf(search.toLowerCase()) !== -1);
  return search === '/' ? [] : items;
};

export const checkRoute = (route: string): boolean => {
  const array: string[] = Object.values(ROUTES_WITHOUT_SIDEBAR_AND_HEADER);
  const result: string[] = searchRequest(array, `/${route.split('/')[1]}`);
  return result.length > 0;
};

export const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const debounce = (func: (value: string) => void, wait = 500) => {
  let timeout: ReturnType<typeof setTimeout> | null;

  const resultFunc = (...args: [value: string]) => {
    const later = () => {
      timeout = null;
      func.apply(this, args);
    };

    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(later, wait);
  };

  resultFunc.cancel = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
  };

  return resultFunc;
};

export const toCamelCaseAll = (value: string): string => capitalizeFirstLetter(value.split('_')
  .map((item) => capitalizeFirstLetter(item)).join(''));

export const getCoords = (elem: HTMLDivElement) => {
  const box = elem.getBoundingClientRect();

  const {
    body, documentElement: {
      scrollTop, scrollLeft, clientTop, clientLeft,
    },
  } = document;

  const newScrollTop = window.scrollY || scrollTop || body.scrollTop;
  const newScrollLeft = window.scrollX || scrollLeft || body.scrollLeft;

  const newClientTop = clientTop || body.clientTop || 0;
  const newClientLeft = clientLeft || body.clientLeft || 0;

  const top = box.top + newScrollTop - newClientTop;
  const left = box.left + newScrollLeft - newClientLeft;

  return {
    top: Math.round(top),
    left: Math.round(left),
    right: box.right,
    spaceToTop: box.top,
    spaceToBottom: window.innerHeight - box.bottom,
    spaceToRight: window.innerWidth - box.left,
    spaceToLeft: box.left,
    width: box.width,
    height: box.height,
  };
};

export const toCamelCase = (str: string): string => str.replace(
  /[-_](.)/g,
  (_: string, group1: string) => group1.toUpperCase(),
);

export const addEndingForRole = (role: UserRole) => `${role.toLowerCase()}s`;

export const extractFileName = (path: string, part: number): string => {
  const parts = path.split('/');
  return parts[parts.length - part];
};

export const extractNameInitials = (name: string): string => name.split(' ').map((n) => n[0]).join(' ');

export const blobToBase64 = (blob: Blob): Promise<string> => new Promise((resolve) => {
  const reader = new FileReader();
  reader.onloadend = () => resolve(reader.result as string);
  reader.readAsDataURL(blob);
});

export const prepareString = (str: string[] | string) => {
  if (typeof str === 'string') {
    return str;
  }
  return str.join('');
};

export const convertPathWithId = (path: string) => path.replace('[id]', '');

export const reduceImage = (imageSrc: Blob, maxSize: number, callback: (dataURL: string) => void) => {
  const image = new Image();

  image.src = URL.createObjectURL(imageSrc);

  image.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    let { width } = image;
    let { height } = image;

    if (width > maxSize || height > maxSize) {
      if (width > height) {
        height *= maxSize / width;
        width = maxSize;
      } else {
        width *= maxSize / height;
        height = maxSize;
      }
    }

    canvas.width = width;
    canvas.height = height;

    if (ctx) {
      ctx.drawImage(image, 0, 0, width, height);
    }

    callback(canvas.toDataURL());
  };
};

export const URLtoFile = (url: string, filename: string): Promise<File> => (
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => new File([blob], filename, { type: 'image/jpg' }))
);

export const correctType = (text: string) => text.replace(/Residential/g, 'In Person');
