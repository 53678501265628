import { Provider } from 'react-redux';
import { appWithTranslation } from 'next-i18next';
import Head from 'next/head';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import InfoProvider from '../src/components/InfoProvider/InfoProvider';
import CheckPermissions from '../src/components/CheckPermissions/CheckPermissions';
import Fonts from '../public/static/fonts/Fonts';
import { useStore } from '../src/store/store';
import IStaticProps from '../src/interfaces/IStaticProps';
import IPageProps from '../src/pages/App/interfaces/IPageProps';
import '../src/pages/App/sass/globals.scss';

const GTM_ID = 'GTM-T8NP8V3';

const MyApp = ({ Component, pageProps }: AppProps) => {
  const { initialReduxState } = pageProps as IPageProps;
  const store = useStore(initialReduxState);

  return (
    <>
      <Head>
        {Fonts()}
        <noscript
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}"
                      height="0" width="0" style="display: none; visibility: hidden;" />`,
          }}
        />
      </Head>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTM_ID}');
      `}
      </Script>

      <Provider store={store}>
        <InfoProvider>
          <CheckPermissions>
            <Component {...pageProps} />
          </CheckPermissions>
        </InfoProvider>
      </Provider>
    </>
  );
};

export async function getStaticProps({ locale }: IStaticProps) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}

export default appWithTranslation(MyApp);
