import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { NextRouter } from 'next/router';
import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { Dispatch, SetStateAction } from 'react';
import { IAuth } from './interfaces/IAuth';
import { IAction } from './interfaces/IAction';
import AuthServices from '../../services/authService';
import { IResponce } from './interfaces/ILoginWorker';
import { TOO_MANY_REQUESTS } from '../../constants/httpStatusCodes';
import { capitalizeFirstLetter } from '../../utils/helper';

export const initialState = {
  firstName: '',
  lastName: '',
};

interface LoginProps {
  email: string,
  password: string,
  setError: Dispatch<SetStateAction<string>>,
  history: NextRouter,
}

export const login = createAsyncThunk(
  'auth/login',
  async (userPata: LoginProps, thunkAPI) => {
    const {
      email, password, setError, history,
    } = userPata;
    try {
      const response:IResponce = await AuthServices.login(email, password);
      return { response: response.data.data, history };
    } catch (error: AxiosError | unknown) {
      if (error instanceof AxiosError) {
        if (error.response?.status === TOO_MANY_REQUESTS) {
          setError('Too many requests');
        } else {
          setError(error.response
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
            ? capitalizeFirstLetter(error.response.data.error.message[0])
            : 'An unexpected error occurred');
        }
      }
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setFirstName: (state: IAuth, action: IAction) => {
      state.firstName = action.payload;
    },
    setLastName: (state:IAuth, action: IAction) => {
      state.lastName = action.payload;
    },
  },
  extraReducers:
    (builder) => {
      builder
        .addCase(login.fulfilled, (state: IAuth, action) => {
          Cookies.set('accessToken', action.payload.response.accessToken);
          Cookies.set('refreshToken', action.payload.response.refreshToken);
          Cookies.set('role', action.payload.response.user.roles[0].name);
          action.payload.history.push('/').catch(() => {});
        })
        .addCase(login.rejected, () => {});
    },
});

export const { setFirstName, setLastName } = authSlice.actions;

export default authSlice.reducer;
