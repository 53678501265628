export enum UserRole {
  Tutor = 'Tutor',
  Participant = 'Participant',
  Mentor = 'Mentor',
}

export enum ConnectionStatus {
  Online = 'online',
  Offline = 'offline',
}

export interface IUserRole {
  createdAt: string;
  id: string;
  name: UserRole;
  updatedAt: string;
}

export interface IUserProfile {
  email: string;
  firstName: string;
  id: string;
  isVerified: boolean;
  lastName: string;
  roles: Array<IUserRole>
}

export interface IParticipantProfileDietary {
  dietaryRequirements: string;
  importantInformation: string;
  medicalConditions: boolean;
  medicalConditionsComment: string;
}

export interface IParticipantProfileContact {
  emergencyContactNumber: string;
  emergencyFullName: string;
}

export interface IParticipantProfileDetails {
  avatar: string;
  birthdayDate: string;
  fullName: string;
  preferredFirstName: string;
  studentId: string;
}

export interface IParticipantProfile {
  dietary: IParticipantProfileDietary,
  emergencyContactDetails: IParticipantProfileContact,
  personalDetails: IParticipantProfileDetails,
}

export interface IParticipantCourseUser {
  fullName: string;
  avatar: string;
}

export interface IParticipantCourseCollege {
  name: string;
  address: string;
}

export interface IIncidentUser {
  id: string;
  fullName: string;
}

export interface IParticipantCourseSession {
  arrivalDate: string;
  departureDate: string;
}

export interface IParticipantCourse {
  college: IParticipantCourseCollege;
  session: IParticipantCourseSession;
  mentor: IParticipantCourseUser;
  tutor: IParticipantCourseUser;
}

export interface IAcademicInformation {
  answer: string;
  id: string;
  question: string;
}
