import { memo, useEffect } from 'react';
import { useRouter } from 'next/router';
import { shallowEqual, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import IInfoProvider from './interfaces/IInfoProvider';
import IStore from '../../store/interfaces/IStore';
import { getUserInfo } from '../../store/user/slice';
import { getCurrentCourse } from '../../store/course/slice';
import { useAppDispatch as useDispatch } from '../../hooks/storeHook';
import { getCurrentCollegeSession } from '../../store/collegeSession/slice';
import { UserRole } from '../../models/user.model';

const InfoProvider = ({ children }: IInfoProvider) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const currentCourse = useSelector(({ course }: IStore) => course.currentCourse, shallowEqual);
  const accessToken = Cookies.get('accessToken') || '';
  const role: UserRole = Cookies.get('role') as UserRole || UserRole.Participant;

  useEffect(() => {
    if (accessToken) {
      dispatch(getUserInfo()).catch(() => {});
    }
    if (router.query.id && currentCourse.classId !== router.query.id && role !== UserRole.Mentor) {
      dispatch(getCurrentCourse(router.query.id as string)).catch(() => {});
    }
    if (router.query.id && router.query.sessionId) {
      dispatch(getCurrentCollegeSession(
        { collegeId: router.query.id as string, session: router.query.sessionId as string },
      )).catch(() => {});
    }
  }, [dispatch, router.query.id, currentCourse, accessToken, router.query.sessionId, role]);

  return children;
};

export default memo(InfoProvider);
