interface ApiRoutes {
  SIGN_IN: string;
  SIGN_OUT: string;
  SIGN_UP: string;
  RESET_PASSWORD: string;
  SET_PASSWORD: string;
  FORGOT_PASSWORD: string;
  REFRESH_TOKEN: string;
  USER_PROFILES: string;
  USER_INFO: string;
  ATTACHMENTS: string;
  AVATAR: string;
  CHAT: string;
  USERS: string;
  GROUP_CHANNELS: string;
  CANCEL: string;
  DOWNLOADED: string;
  AFTER_COURSE: string;
  COURSES: string;
  COURSE_SESSION: string;
  CLASSES: string;
  EVENTS: string;
  ACADEMIC_INFORMATION: string;
  INCIDENTS: string;
  CLASS: string;
  PARTICIPANTS_TASKS: string;
  PARTICIPANTS: string;
  PARTICIPANT: string;
  PROFILE: string;
  COLLEGE_MANAGERS: string;
  MENTORS_SESSION: string;
  USER_TASKS: string;
  IMPORTANT_UPDATES: string;
  DASHBOARD: string;
  VIEW: string;
  ATTENDANCE_REGISTERS: string;
  ADDITIONAL_PARTICIPANTS: string;
  REGISTER: string;
  TUTORS: string;
  MENTORS: string;
  MENTOR: string;
  EVALUATION: string;
  IMMERSE_ARRIVALS: string;
  IMMERSE_PARTICIPANT_ARRIVALS: string;
  SESSION: string;
  COLLEGES_SESSIONS: string;
}

const API_ROUTES: ApiRoutes = {
  SIGN_UP: '/sign-up',
  SIGN_IN: 'auth/sign-in',
  SIGN_OUT: 'auth/sign-out',
  RESET_PASSWORD: 'auth/reset-password',
  SET_PASSWORD: 'auth/set-password',
  REFRESH_TOKEN: 'auth/refresh-token',
  FORGOT_PASSWORD: 'auth/forgot-password',
  USER_PROFILES: 'user-profiles',
  PROFILE: '/profile',
  USER_INFO: 'users/user',
  AVATAR: '/avatar/',
  INCIDENTS: 'incidents',
  ATTACHMENTS: 'attachments',
  CHAT: 'chat/',
  MENTORS_SESSION: 'mentors-sessions-information/',
  ACADEMIC_INFORMATION: 'academic-information',
  GROUP_CHANNELS: 'group-channels/',
  COLLEGE_MANAGERS: 'college-managers/',
  USERS: 'users/',
  CLASSES: 'classes/',
  CLASS: '/class',
  COURSES: 'courses',
  CANCEL: '/cancel',
  EVENTS: 'events',
  PARTICIPANTS_TASKS: 'participants-tasks/',
  PARTICIPANTS: 'participants',
  IMPORTANT_UPDATES: 'important-updates/',
  USER_TASKS: '/user/tasks',
  COURSE_SESSION: 'courses-sessions',
  DOWNLOADED: '/download/',
  DASHBOARD: '/dashboard/',
  AFTER_COURSE: '/after-course/',
  VIEW: '/view/',
  ATTENDANCE_REGISTERS: 'attendance-registers',
  ADDITIONAL_PARTICIPANTS: '/additional-participants/participants',
  REGISTER: '/register',
  TUTORS: 'tutors',
  MENTORS: 'mentors',
  MENTOR: 'mentor',
  EVALUATION: '/evaluation',
  IMMERSE_ARRIVALS: 'immerse-arrivals',
  IMMERSE_PARTICIPANT_ARRIVALS: 'immerse-participant-arrivals',
  PARTICIPANT: '/participant/',
  SESSION: '/session/',
  COLLEGES_SESSIONS: 'colleges-sessions',
};

export default API_ROUTES;
