export const EMPTY_ARRAY = 0;

export const CORRECT_ARRAY = 1;

export const NEXT_STEP = 1;

export const FIRST_PAGE = 1;

export const ONE_PAGE = 1;

export const STUDENTS_PER_PAGE = 8;

export const MAX_STEP = 5;

export const EVENTS_PER_PAGE = 50;

export const ATTENDANCES_PER_PAGE = 10;

export const INCIDENTS_PER_PAGE = 10;

export const STEP_PROFILE = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
  FOURTH: 4,
  FIFTH: 5,
};

export enum PasswordStatuses {
  RESET = 'reset',
  SET = 'set',
}

export const TEXT_CONSTANTS = {
  LAST_NAME: 'lastName',
  FIRST_NAME: 'firstName',
};

export const PHONE_INPUT_PLACEHOLDER = '(0) 000 000 0000';

export const SELECT_PAGE_SIZE = 10;

export const TOKEN_HELPER = {
  ACCESS: 'accessToken',
  REFRESH: 'refreshToken',
  ROLE: 'role',
};
