import {
  createSlice, createAsyncThunk,
} from '@reduxjs/toolkit';
import $api from '../../http';
import { IResponce, ISetProfilePayload, IDataUSer } from './interfaces/IResponce';
import IProfile from './interfaces/IProfile';
import UserServices from '../../services/userService';
import API_ROUTES from '../../constants/apiRoutes';
import { addNotification } from '../notifications/slice';

export const initialState: IProfile = {
  avatar: '',
  steps: {
    personalDetails: {
      firstName: '',
      lastName: '',
      pronouns: '',
      preferredFirstName: '',
    },
    contactDetails: {
      personalEmailAddress: '',
      schoolEmailAddress: '',
      firstLineOfAddress: '',
      secondLineOfAddress: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
    },
    academicInformation: {
      school: '',
      schoolWebsite: '',
      education: '',
    },
    emergencyContactDetails: {
      primaryEmergencyFirstName: '',
      primaryEmergencyLastName: '',
      primaryEmergencyRelationship: '',
      primaryEmergencyContactNumber: '',
      secondaryEmergencyFirstName: '',
      secondaryEmergencyLastName: '',
      secondaryEmergencyRelationship: '',
      secondaryEmergencyContactNumber: '',
    },
    dietary: {
      dietaryRequirements: '',
      medicalConditions: true,
      medicalConditionsComment: '',
      optOut: true,
    },
  },
};

export const getProfileInfo = createAsyncThunk(
  'profile/getInfo',
  async (_, { rejectWithValue }) => {
    try {
      const response:IResponce = await $api.get(API_ROUTES.USER_PROFILES);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const setProfileAvatar = createAsyncThunk(
  'profile/setAvatar',
  async ({ file, isUpdate = false }: { file: File, isUpdate: boolean }, { rejectWithValue, dispatch }) => {
    const formData = new FormData();
    formData.append('file', file, file.name);

    try {
      const { status } = await UserServices.setAvatar(formData, isUpdate);

      if (status === 200 || status === 201) {
        dispatch(addNotification({ message: 'Test', id: 1 }));
        return file;
      }
      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getProfileAvatar = createAsyncThunk(
  'profile/getAvatar',
  async (_, { rejectWithValue, getState }) => {
    const { profile: { avatar } } = getState() as { profile: IProfile };

    if (!avatar) {
      try {
        const { data } = await UserServices.getAvatar();
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
    return null;
  },
);

export const setProfileInfo = createAsyncThunk(
  'profile/setInfo',
  async (payload: ISetProfilePayload, thunkAPI) => {
    const { currentStep, valueInputs, medicalPayload } = payload;

    let body;
    if (currentStep === 'personalDetails') {
      body = {
        personalDetails: {
          pronouns: valueInputs.pronouns,
          preferredFirstName: valueInputs.preferredFirstName,
        },
      };
    } else {
      body = {
        [currentStep]: { ...valueInputs },
      };
    }
    if (currentStep === 'dietary') {
      body = {
        dietary: { ...medicalPayload },
      };
    }
    try {
      const response:IResponce = await $api
        .patch(
          API_ROUTES.USER_PROFILES,
          body,
        );
      return response.data.data;
    } catch ({ message }) {
      return thunkAPI.rejectWithValue(message as string);
    }
  },
);

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    resetState: () => initialState,
    setStateUsers: (state: IProfile, { payload } : IDataUSer) => {
      state.steps = payload;
    },
  },
  extraReducers:
    (builder) => {
      builder
        .addCase(getProfileInfo.fulfilled, (state: IProfile, action) => {
          state.steps = action.payload;
        })
        .addCase(getProfileInfo.rejected, () => {})

        .addCase(setProfileInfo.fulfilled, (state: IProfile, action) => {
          state.steps = action.payload;
        })
        .addCase(setProfileInfo.rejected, () => {});
      builder
        .addCase(getProfileAvatar.fulfilled, (state: IProfile, action) => {
          if (action.payload) {
            state.avatar = URL.createObjectURL(action.payload);
          }
        })
        .addCase(getProfileAvatar.rejected, (state: IProfile) => {
          state.avatar = initialState.avatar;
        });
      builder
        .addCase(setProfileAvatar.fulfilled, (state: IProfile, action) => {
          if (action.payload) {
            state.avatar = URL.createObjectURL(action.payload);
          }
        });
    },
});

export default profileSlice.reducer;

export const { setStateUsers, resetState } = profileSlice.actions;
