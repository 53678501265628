import $api from '../http';
import API_ROUTES from '../constants/apiRoutes';

export default class AuthServices {
  static async login(email: string, password: string) {
    return $api.post(
      API_ROUTES.SIGN_IN,
      { email, password },
    );
  }

  static async logout() {
    return $api.post(API_ROUTES.SIGN_OUT);
  }

  static async resetPassword(password:string, token: string) {
    return $api.patch(
      API_ROUTES.RESET_PASSWORD,
      { password, token },
    );
  }

  static async sendEmail(email: string, isReset: boolean) {
    const callbackPath = isReset ? 'password/reset' : 'password/set';
    return $api[isReset ? 'patch' : 'post'](
      isReset ? API_ROUTES.FORGOT_PASSWORD : API_ROUTES.SET_PASSWORD,
      {
        email,
        callbackUrl: `${process.env.NEXT_PUBLIC_FRONTEND_URL || ''}${callbackPath}`,
      },
    );
  }
}
