import { AxiosResponse } from 'axios';
import $api from '../http';
import API_ROUTES from '../constants/apiRoutes';
import {
  EventActivityType, ICourseData, ICoursesData, IEventsData,
} from './interfaces/ICourseService';
import { EVENTS_PER_PAGE } from '../constants/helperConstants';
import { UserRole } from '../models/user.model';

export default class CourseServices {
  static async getCourse(classId: string): Promise<AxiosResponse<ICourseData>> {
    return $api.get(
      `${process.env.NEXT_PUBLIC_API_URL || ''}${API_ROUTES.COURSES}/${API_ROUTES.CLASSES}${classId}`,
    );
  }

  static async getCourses(role: UserRole): Promise<AxiosResponse<ICoursesData>> {
    if (role) {
      return $api.get(
        `${process.env.NEXT_PUBLIC_API_URL || ''}${API_ROUTES.COURSES}/${role}`,
      );
    }
    return new Promise(null as never);
  }

  static async getEvents(classId: string): Promise<AxiosResponse<IEventsData>> {
    return $api.get(
      `${process.env.NEXT_PUBLIC_API_URL || ''}${API_ROUTES.EVENTS}/${classId}${API_ROUTES.CLASS}`,
      {
        params: {
          activityType: EventActivityType.AcademicSessions,
          isBooked: true,
          take: EVENTS_PER_PAGE,
          dateFrom: new Date(new Date().setHours(0, 0, 0, 0)),
          dateTo: new Date(new Date().setHours(23, 59, 59, 59)),
        },
      },
    );
  }

  static async signUpEvent(eventId: string) {
    return $api.post(
      `${process.env.NEXT_PUBLIC_API_URL || ''}${API_ROUTES.EVENTS}/${eventId}${API_ROUTES.SIGN_UP}`,
    );
  }

  static async cancelEvent(eventId: string) {
    return $api.delete(
      `${process.env.NEXT_PUBLIC_API_URL || ''}${API_ROUTES.EVENTS}/${eventId}${API_ROUTES.CANCEL}`,
    );
  }
}
