import { AxiosResponse } from 'axios';
import $api from '../http';
import API_ROUTES from '../constants/apiRoutes';
import { ICollegeSessionResponse, ICollegesSessionsResponse } from './interfaces/ICollegesSessionsServise';

export default class CollegeSessionServices {
  static async getCollegeSession(collegeId: string, session: string): Promise<AxiosResponse<ICollegeSessionResponse>> {
    return $api.get(
      `${process.env.NEXT_PUBLIC_API_URL || ''}${API_ROUTES.COLLEGES_SESSIONS}/${collegeId}/${session}`,
    );
  }

  static async getCollegeSessions(): Promise<AxiosResponse<ICollegesSessionsResponse>> {
    return $api.get(
      `${process.env.NEXT_PUBLIC_API_URL || ''}${API_ROUTES.COLLEGES_SESSIONS}`,
    );
  }
}
