import {
  useCallback,
  useEffect,
  memo,
} from 'react';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import ICheckPermissions from './interfaces/ICheckPermissions';
import { UserRole } from '../../models/user.model';
import rolesNotAvailablePaths from '../../constants/rolesNotAvailablePaths';
import { convertPathWithId } from '../../utils/helper';

const pathIsAvailable = (role: UserRole, pathname: string) => !rolesNotAvailablePaths[role].includes(pathname);

const CheckPermissions = ({ children }: ICheckPermissions) => {
  const router = useRouter();
  const role: UserRole = Cookies.get('role') as UserRole || UserRole.Participant;

  const pathNotAllowed = useCallback(
    () => !pathIsAvailable(role, convertPathWithId(router.pathname)),
    [router.pathname, role],
  );

  useEffect(() => {
    if (pathNotAllowed()) {
      router.push('/').catch(() => {});
    }
  }, [pathNotAllowed, router]);

  if (!pathNotAllowed()) {
    return children;
  }

  return children;
};

export default memo(CheckPermissions);
