import { createSlice } from '@reduxjs/toolkit';
import IChat from './interfaces/IChat';

export const initialState: IChat = {
  channelCustomType: '',
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    resetState: () => initialState,
    setChannelCustomType: (state, { payload }: { payload: string }) => {
      state.channelCustomType = payload;
    },
  },
});

export default chatSlice.reducer;

export const { resetState, setChannelCustomType } = chatSlice.actions;
