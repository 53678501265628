import {
  ICollegeManager,
  ICourseMentorInformation,
  ICourseRole,
  IEvent,
  IImportantUpdate,
  ITask,
  ICollege,
} from '../../models/course.model';
import { IItemsWithCount } from '../../models/global.model';

export interface IImportantUpdatesData {
  data: {
    data: Array<IImportantUpdate>;
    count: number;
  }
}

export enum EventActivityType {
  AcademicSessions = 'ACADEMIC_SESSIONS',
  SocialActivities = 'SOCIAL_ACTIVITIES',
  EatingActivities = 'EATING_ACTIVITIES',
}

export interface IEventsData {
  data: IItemsWithCount<IEvent>
}

export interface ITasksData {
  data: IItemsWithCount<ITask>
}

export interface ICourseData {
  data: ICourseRole
}

export interface ICourseMentorSession {
  data: ICourseMentorInformation
}

export interface ICoursesData {
  data: ICourseRole[]
}

export interface ICollegeManagerData {
  data: ICollegeManager
}

export interface ICollegeData {
  data: ICollege
}
