import { AxiosResponse } from 'axios';
import $api from '../http';
import API_ROUTES from '../constants/apiRoutes';
import { IChannelsData } from './interfaces/IMessagesService';
import { UserRole } from '../models/user.model';

export default class MessagesService {
  static async getChannelList(
    classOrCollegeId: string,
    role: UserRole,
    mentorUserId: string,
    session: string,
  ): Promise<AxiosResponse<IChannelsData>> {
    if (role === UserRole.Mentor) {
      return $api.get(
        `${process.env.NEXT_PUBLIC_API_URL
          || ''}${API_ROUTES.CHAT}${API_ROUTES.GROUP_CHANNELS}${API_ROUTES.USERS}`
          .concat(`?collegeId=${classOrCollegeId}&session=${session}&includeMembersIds=${mentorUserId}`),
      );
    }
    return $api.get(
      `${process.env.NEXT_PUBLIC_API_URL
          || ''}${API_ROUTES.CHAT}${API_ROUTES.GROUP_CHANNELS}${API_ROUTES.USERS}?classId=${classOrCollegeId}`,
    );
  }
}
