import { ROUTES } from './routes';
import { UserRole } from '../models/user.model';

const rolesNotAvailablePaths: {
  readonly [key in UserRole]: Array<string>
} = {
  [UserRole.Participant]: [
    ROUTES.STUDENTS,
    ROUTES.EVALUATIONS,
    ROUTES.ATTENDANCE,
    ROUTES.ATTENDANCE_REGISTER,
    ROUTES.INCIDENT_REPORT,
  ],
  [UserRole.Mentor]: [
    ROUTES.YOUR_FEEDBACK,
  ],
  [UserRole.Tutor]: [
    ROUTES.YOUR_FEEDBACK,
  ],
};

export default rolesNotAvailablePaths;
