import axios, { AxiosResponse, AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { setToLocalStorage } from '../utils/helper';
import API_ROUTES from '../constants/apiRoutes';

interface RefreshTokenResponse {
  data: {
    accessToken?: string;
    refreshToken?: string;
  }
}

const apiUrl = process.env.NEXT_PUBLIC_API_URL || '';
let isRefreshing = false;
const refreshSubscribers: Array<(token: string) => void> = [];

const subscribeTokenRefresh = (cb: (token: string) => void) => {
  refreshSubscribers.push(cb);
};

const onRefreshed = (accessToken: string, refreshToken: string) => {
  refreshSubscribers.forEach((cb) => cb(accessToken));
  Cookies.set('accessToken', accessToken);
  Cookies.set('refreshToken', refreshToken);
};

const $api = axios.create({
  baseURL: apiUrl,
});

$api.interceptors.request.use((config) => {
  const accessToken = Cookies.get('accessToken') || '';
  const configRequest = config;
  configRequest.headers.Authorization = `Bearer ${accessToken}`;
  return configRequest;
});

const refreshTokensHandle = (access_token: string | undefined, refresh_token: string | undefined) => {
  if (access_token && refresh_token) {
    isRefreshing = false;
    onRefreshed(access_token, refresh_token);
  } else {
    setToLocalStorage('errorMessage', 'Invalid response from server');
  }
};

const requestRefreshToken = () => {
  const refreshTokenCookie = Cookies.get('refreshToken') || '';
  if (!isRefreshing && refreshTokenCookie) {
    isRefreshing = true;
    axios.post< RefreshTokenResponse >(
      `${apiUrl}${API_ROUTES.REFRESH_TOKEN}`,
      { refreshToken: refreshTokenCookie },
    )
      .then((response) => {
        isRefreshing = false;
        const { accessToken, refreshToken } = response.data.data || {};
        refreshTokensHandle(accessToken, refreshToken);
      }).catch((e) => {
        window.location.href = '/login';
        Cookies.remove('accessToken');
        Cookies.remove('refreshToken');
        setToLocalStorage('errorMessage', (e as Error).message);
      });
  }
};

$api.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error) => {
    const { config, response: { status } = {} } = error as AxiosError;
    const originalRequest = config;
    if (status === 401) {
      requestRefreshToken();
      return new Promise((resolve) => {
        subscribeTokenRefresh((response) => {
          if (originalRequest) {
            originalRequest.headers.Authorization = `Bearer ${response}`;
            resolve(axios(originalRequest));
          }
        });
      });
    }
    return Promise.reject(error);
  },
);

export default $api;
