import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ICollegeSession from './interfaces/ICollegeSession';
import CollegeSessionServices from '../../services/collegeSessionServices';

export const initialState: ICollegeSession = {
  currentCollegeSession: {
    collegeId: '',
    collegeName: '',
    startAt: '',
    endAt: '',
    address: '',
    session: '',
    participantsCount: 0,
  },
};

export const getCurrentCollegeSession = createAsyncThunk(
  'collegeSession/getCollegeSession',
  async ({ collegeId, session }: { collegeId: string, session: string }, { rejectWithValue }) => {
    try {
      const { data } = await CollegeSessionServices.getCollegeSession(collegeId, session);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const collegeSession = createSlice({
  name: 'collegeSession',
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentCollegeSession.pending, (state) => {
        state.currentCollegeSession = initialState.currentCollegeSession;
      })
      .addCase(getCurrentCollegeSession.fulfilled, (state, action) => {
        state.currentCollegeSession = action.payload.data;
      });
  },
});

export default collegeSession.reducer;

export const { resetState } = collegeSession.actions;
