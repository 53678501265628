import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ProgressStatus } from '../../models/course.model';
import CourseServices from '../../services/courseServices';
import ICourse from './interfaces/ICourse';

export const initialState: ICourse = {
  currentCourse: {
    classId: '',
    collegeId: '',
    courseName: '',
    courseId: '',
    courseSubject: '',
    collegeName: '',
    locationName: '',
    endAt: '',
    startAt: '',
    type: '',
    classroomName: '',
    collegeAddress: '',
    ageRange: '',
    session: '',
    progressStatus: ProgressStatus.DuringCourse,
  },
  events: null,
};

export const getCurrentCourse = createAsyncThunk(
  'course/getCourse',
  async (classId: string, { rejectWithValue }) => {
    try {
      const { data } = await CourseServices.getCourse(classId);

      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getCourseEvents = createAsyncThunk(
  'course/getCourseEvents',
  async (classId: string, { rejectWithValue }) => {
    try {
      const { data } = await CourseServices.getEvents(classId);

      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const userSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentCourse.pending, (state) => {
        state.currentCourse = initialState.currentCourse;
      })
      .addCase(getCurrentCourse.fulfilled, (state, action) => {
        state.currentCourse = action.payload;
      });
    builder
      .addCase(getCourseEvents.fulfilled, (state, action) => {
        state.events = action.payload;
      });
  },
});

export default userSlice.reducer;

export const { resetState } = userSlice.actions;
